<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item :to="{ path: '/zuzhi/home' }"
        >我的组织</el-breadcrumb-item
      >
      <el-breadcrumb-item>我的组织详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!--当前权限等级-->
    <el-card class="right-name">
      <div style="font-size:30px;font-weight:bold;" class="course-title">
        {{ groupName }}
      </div>
    </el-card>
    <!--绑定的上级-->
    <el-card class="righttable-up">
      <i class="el-icon-star-off"></i>
      <span>我的上级</span>
      <el-button
        type="warning"
        style="float:right;margin-left:10px"
        size="small"
        @click="openbindHistory()"
        >绑定历史</el-button
      >
      <el-table
        :data="bindUp"
        style="width: 100%; margin-top:20px;"
        fit
        border
        stripe
      >
        <el-table-column
          prop="uname"
          label="管理员姓名"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="oname"
          label="管理单位名称"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column label="绑定状态" min-width="100" align="center">
          <el-tag type="success">已绑定</el-tag>
        </el-table-column>
        <el-table-column
          label="是否向上级开放考勤"
          min-width="100"
          align="center"
        >
          <template slot-scope="scope">
            <el-dropdown
              split-button
              type="primary"
              size="small"
              @click="handleClick"
            >
              {{
                scope.row.whethersee === '1' ? '开放考勤结果' : '关闭考勤结果'
              }}
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="openResult(scope.row)"
                  >开放考勤结果</el-dropdown-item
                >
                <el-dropdown-item @click.native="closeResult(scope.row)"
                  >关闭考勤结果</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next,jumper"
          :current-page.sync="formInline.pageNum"
          :page-size="formInline.pageSize"
          :page-sizes="[5, 10, 15]"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
    <!--绑定的下级-->
    <el-card class="righttable right-lay">
      <i class="el-icon-user"></i>
      <span>我的下级</span>
      <div class="search-bar">
        <el-input
          v-model="formInlineOrg.oname"
          placeholder="请输入组织名或课程名进行查询"
          style="width:240px;"
          size="small"
          class="inputstyle"
          @keydown.enter.native="searchOrg"
          prefix-icon="el-icon-search"
        >
        </el-input>
        <el-select
          v-model="searchCategory"
          placeholder="搜索类别"
          style="width: 100px"
          size="small"
          class="input-select"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-button plain type="primary" size="small" @click="clearSearch"
          >重置</el-button
        >
        <el-button type="primary" size="small" @click="searchOrg"
          >查询</el-button
        >
      </div>
      <div class="right-up">
        <!-- <i class="el-icon-tickets"></i>
        <span>组织申请列表</span> -->
        <el-table
          :data="listData"
          border
          stripe
          style="width: 100%"
          :default-sort="{ prop: 'date', order: 'descending' }"
        >
          <el-table-column
            label="序号"
            type="index"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="uname"
            label="管理员"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="申请类型"
            prop="goupType"
            min-width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag type="info" light v-if="scope.row.groupType === 2"
                >组织</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            label="组织名称"
            prop="oname"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="phone"
            label="管理员电话"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="application_status"
            sortable
            label="操作"
            align="center"
            min-width="150"
          >
            <template slot-scope="scope">
              <el-button
                type="danger"
                size="mini"
                @click="handleUnbinding(scope.$index, scope.row)"
                >解绑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
            background
            @size-change="handleOrgSizeChange"
            @current-change="handleOrgCurrentChange"
            layout="total, sizes, prev, pager, next,jumper"
            :current-page.sync="formInlineOrg.pageNum"
            :page-size="formInlineOrg.pageSize"
            :page-sizes="[5, 10, 15]"
            :total="orgTotal"
          ></el-pagination>
        </div>
      </div>
      <div class="right-down">
        <!-- <i class="el-icon-tickets"></i>
        <span>课程申请列表</span> -->
        <el-table
          :data="couListData"
          border
          stripe
          style="width: 100%"
          :default-sort="{ prop: 'date', order: 'descending' }"
        >
          <el-table-column
            label="序号"
            type="index"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="uname"
            label="教师"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="申请类型"
            prop="goupType"
            min-width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag type="primary" light v-if="scope.row.groupType === 1"
                >课程</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            label="课程名称"
            prop="name"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="week"
            label="周次"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="section"
            label="节数"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="application_status"
            sortable
            label="操作"
            align="center"
            min-width="150"
          >
            <template slot-scope="scope">
              <el-button
                type="danger"
                size="mini"
                @click="handleUnbinding(scope.$index, scope.row)"
                >解绑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
            background
            @size-change="handleCouSizeChange"
            @current-change="handleCouCurrentChange"
            layout="total, sizes, prev, pager, next,jumper"
            :current-page.sync="formInlineCou.pageNum"
            :page-size="formInlineCou.pageSize"
            :page-sizes="[5, 10, 15]"
            :total="couTotal"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <!--绑定历史对话框-->
    <el-dialog
      title="绑定历史"
      :visible.sync="bindHistorydialogVisible"
      width="50%"
    >
      <el-table
        :data="bindHistoryList"
        style="width: 100%; margin-top:20px;"
        fit
        border
        stripe
      >
        <el-table-column
          prop="oname"
          label="上级名称"
          min-width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="application_status"
          label="绑定状态"
          min-width="80"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag type="warning" v-if="scope.row.application_status === 1"
              >待审核</el-tag
            >
            <el-tag
              type="success"
              v-else-if="scope.row.application_status === 2"
              >绑定中</el-tag
            >
            <el-tag type="danger" v-else-if="scope.row.application_status === 3"
              >已驳回</el-tag
            >
            <el-tag type="danger" v-else>已解除</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="createtime"
          label="申请时间"
          min-width="100"
          align="center"
        >
        </el-table-column>
      </el-table>
      <div class="pagination-container2">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next,jumper"
          :current-page.sync="formInline.pageNum"
          :page-size="formInline.pageSize"
          :page-sizes="[5, 10, 15]"
          :total="total"
        ></el-pagination>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="bindHistorydialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import qs from 'qs'

export default {
  data() {
    return {
      options: [
        {
          value: 'organization',
          label: '组织'
        },
        {
          value: 'course',
          label: '课程'
        }
      ],
      searchCategory: '',
      nowRight: '',
      //上级单位
      bindUp: [],
      listData: [], //组织绑定列表
      couListData: [], //课程绑定列表
      //绑定历史
      bindHistorydialogVisible: false,
      bindHistoryList: [],
      //绑定历史  //分页
      formInline: {
        pageNum: 1,
        pageSize: 5
      },
      formInlineOrg: {
        oname: '',
        pageNum: 1,
        pageSize: 5
      },
      formInlineCou: {
        pageNum: 1,
        pageSize: 5
      },
      tid: null,
      uid: '',
      groupName: '',
      total: 0, //历史总页码
      orgTotal: 0,
      couTotal: 0,
      //绑定申请对话框
      rightdialogVisible: false,
      applyForm: {
        value: []
      },
      myprop: {
        label: 'groupName',
        value: 'id'
      },
      soid: '' //父级组织的id
    }
  },
  created() {
    this.uid = sessionStorage.getItem('uid')
    this.groupName = this.$route.query.fatherName
    this.soid = this.$route.query.soid
    this.getRoleofZuzhi()
    this.getList()
    this.getCouList()
  },
  methods: {
    //重置
    clearSearch() {
      // this.formInlineOrg = Object.assign({}, defaultFormInline)
      this.formInlineOrg.oname = null
      this.searchCategory = null
      this.getList()
      this.getCouList()
    },
    //查询
    async searchOrg() {
      if (!this.searchCategory) {
        this.$message({
          message: '请选择搜索类别',
          type: 'warning'
        })
        return // 阻止继续搜索操作
      }
      // 根据选中的搜索类别执行相应的搜索操作
      if (this.searchCategory === 'organization') {
        const { data: res } = await this.$http.get('/org/applybindbyoname', {
          params: {
            oname: this.formInlineOrg.oname,
            pageNum: this.formInlineOrg.pageNum,
            pageSize: this.formInlineOrg.pageSize,
            oid: this.soid
          }
        })
        if (res.data != null) {
          this.listData = res.data.map(e => {
            return {
              ...e,
              groupType: 2
            }
          })
          this.total = res.total
          this.$message.success('查询成功')
        }
      } else if (this.searchCategory === 'course') {
        const { data: res } = await this.$http.get('/cou/applybindcoubyname', {
          params: {
            name: this.formInlineOrg.oname,
            pageNum: this.formInlineCou.pageNum,
            pageSize: this.formInlineCou.pageSize,
            oid: this.soid
          }
        })
        if (res.data != null) {
          this.couListData = res.data.map(e => {
            return {
              ...e,
              groupType: 1
            }
          })
          this.total = res.total
          this.$message.success('查询成功')
        }
      }
    },
    //解除绑定
    async handleUnbinding(index, row) {
      //解绑课程
      if (row.groupType === 1) {
        const { data: res } = await this.$http.post(
          '/cou/couuntiebind',
          qs.stringify({ coid: row.coid })
        )
        if (res.code == 200) {
          this.getCouList()
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      } else {
        const { data: res } = await this.$http.post(
          '/org/untiebind',
          qs.stringify({
            soid: row.soid
          })
        )
        if (res.code == 200) {
          this.getList()
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      }
    },
    //解除下级绑定
    async closedown(mydata) {
      const { data: res } = await this.$http.post(
        '/org/untiebind',
        qs.stringify({
          soid: mydata.soid
        })
      )
      if (res.code == 200) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
        this.getDownList()
      } else {
        this.$message.error(res.message)
      }
    },
    //打开绑定历史
    openbindHistory() {
      this.getHistoryList()
      this.bindHistorydialogVisible = true
    },
    //获取绑定历史记录
    async getHistoryList() {
      const { data: res } = await this.$http.get('/org/applybindhistory', {
        params: {
          pageNum: this.formInline.pageNum,
          pageSize: this.formInline.pageSize,
          oid: this.soid
        }
      })
      if (res != null) {
        this.total = res.total
        this.bindHistoryList = res.data
      }
    },

    //获取绑定的上级
    async getRoleofZuzhi() {
      const { data: res } = await this.$http.get('/org/applybindsup', {
        params: {
          oid: this.soid,
          pageSize: 3,
          pageNum: 1
        }
      })
      if (res.code === 200) {
        // if (this.bindUp.length == 0) {
        //   this.bindUp.push(res.data)
        // }

        // if (res.data.whetherSeeAttendance == 0) {
        //   this.resultKaoq = '关闭考勤结果'
        // } else {
        //   this.resultKaoq = '开放考勤结果'
        // }
        // sessionStorage.setItem('groupId', res.data.adminGroupEntity.id)
        // this.nowRight = res.data.adminGroupEntity.groupName + '管理员'
        this.bindUp = res.data
        this.total = res.total
      }
    },
    async openResult(row) {
      const { data: res } = await this.$http.post(
        '/org/updatewhethersee',
        qs.stringify({
          // groupId: sessionStorage.getItem('groupId'),
          // whetherSeeAttendance: 1
          oid: this.soid,
          sid: row.oid,
          whethersee: '1'
        })
      )
      if (res.code == 200) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
        this.getRoleofZuzhi()
      }
    },
    async closeResult(row) {
      const { data: res } = await this.$http.post(
        '/org/updatewhethersee',
        qs.stringify({
          // groupId: sessionStorage.getItem('groupId'),
          // whetherSeeAttendance: 1
          oid: this.soid,
          sid: row.oid,
          whethersee: '0'
        })
      )
      if (res.code == 200) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
        this.getRoleofZuzhi()
      }
    },
    async getList() {
      const { data: res } = await this.$http.get('/org/applybind', {
        params: {
          oid: this.soid,
          pageNum: this.formInlineOrg.pageNum,
          pageSize: this.formInlineOrg.pageSize
        }
      })
      if (res.code == 200) {
        this.listData = res.data.map(e => {
          return {
            ...e,
            groupType: 2
          }
        })
        this.orgTotal = res.total
      }
    },
    async getCouList() {
      const { data: res } = await this.$http.get('/cou/applybindcou', {
        params: {
          oid: this.soid,
          pageNum: this.formInlineCou.pageNum,
          pageSize: this.formInlineCou.pageSize
        }
      })
      if (res.code == 200) {
        this.couListData = res.data.map(e => {
          return {
            ...e,
            groupType: 1
          }
        })
        this.couTotal = res.total
      }
    },
    //历史绑定
    // 更改了每页大小
    handleSizeChange(val) {
      this.formInline.pageNum = 1
      this.formInline.pageSize = val
      this.getHistoryList()
    },
    // 更改了第几页
    handleCurrentChange(val) {
      this.formInline.pageNum = val
      this.getHistoryList()
    },
    // 实现组织分页
    // 更改了每页大小
    handleOrgSizeChange(val) {
      this.formInlineOrg.pageNum = 1
      this.formInlineOrg.pageSize = val
      this.getList()
    },
    // 更改了第几页
    handleOrgCurrentChange(val) {
      this.formInlineOrg.pageNum = val
      this.getList()
    },
    // 实现课程分页
    // 更改了每页大小
    handleCouSizeChange(val) {
      this.formInlineCou.pageNum = 1
      this.formInlineCou.pageSize = val
      this.getCouList()
    },
    // 更改了第几页
    handleCouCurrentChange(val) {
      this.formInlineCou.pageNum = val
      this.getCouList()
    }
  }
}
</script>

<style lang="scss" scoped>
.search-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  .inputstyle {
    margin-right: 10px;
  }
  .input-select {
    margin-right: 5px;
  }
}

.course-title {
  text-align: center;
}
</style>
